import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"wizard__content--item-container px-0"},[_c('a-form',{ref:"form",attrs:{"submit":_vm.submit}},[(!_vm.companyControl.show)?_c('stepper-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('p',{staticClass:"text-body-2"},[_vm._v(" Our in-house authors will create you the ideal Amp. Just answer the following questions and include your media files. We'll jazz it up with the right language to grab attention and gain traffic and visibility. ")])]},proxy:true},{key:"info",fn:function(){return [(_vm.companyControl.loading)?_c(VSkeletonLoader,{attrs:{"type":"list-item-avatar-three-line"}}):_vm._e(),(
                        !_vm.companyControl.loading &&
                        _vm.companyControl.selectedCompany &&
                        _vm.$vuetify.breakpoint.mdAndUp
                    )?_c('company-card-small',{attrs:{"company":_vm.companyControl.selectedCompany},on:{"click":function($event){_vm.companyControl.show = true}}}):_vm._e()]},proxy:true}],null,false,1588385100)}):_vm._e(),(!_vm.companyControl.show)?_c('stepper-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c(VRow,[_c(VCol,{class:{
                            'col-8': _vm.$vuetify.breakpoint.xsOnly
                        },attrs:{"md":"6"}},[_c('a-select-input',{attrs:{"label":"Company","rules":"required","items":_vm.companies,"item-text":"name","item-value":"id","disabled":_vm.loading,"placeholder":"Select a company"},model:{value:(_vm.requestData.company_id),callback:function ($$v) {_vm.$set(_vm.requestData, "company_id", $$v)},expression:"requestData.company_id"}})],1),_c(VCol,{class:{
                            'col-4': _vm.$vuetify.breakpoint.xsOnly,
                            'pt-6': _vm.$vuetify.breakpoint.mdAndUp
                        }},[(_vm.companyControl.selectedCompany)?_c(VBtn,{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();_vm.companyControl.show = true}}},[_vm._v(" Edit "),_c(VIcon,{attrs:{"right":"","small":"","color":"primary"}},[_vm._v(" pencil ")])],1):_vm._e(),_c(VBtn,{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.addNewCompany.apply(null, arguments)}}},[_vm._v(" Add "),_c(VIcon,{attrs:{"right":"","small":"","color":"primary"}},[_vm._v(" plus-circle ")])],1)],1)],1)]},proxy:true}],null,false,3308341071)}):_vm._e(),(_vm.companyControl.show)?_c('stepper-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('company-editor',{ref:"companyEditor",staticClass:"px-0 pt-0",attrs:{"company-id":_vm.selectedCompanyId,"is-creating":_vm.companyControl.isCreating},on:{"cancelled":_vm.onCompanyCancelled,"saved":_vm.onCompanySaved},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c(VSkeletonLoader,{attrs:{"loading":"","type":"article"}})]},proxy:true}],null,false,3530317992)})]},proxy:true}],null,false,4173968543)}):_vm._e(),(!_vm.companyControl.show)?_c('stepper-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('a-text-input',{attrs:{"textarea":"","rules":"required","custom-messages":{
                        required: 'Provide a theme for your amp'
                    },"label":"Recommend a main theme for your Amp","placeholder":"I want it to focus on ... and ...","rows":"5","auto-grow":""},on:{"focus":function($event){return _vm.showInfoAndFit('theme')}},model:{value:(_vm.requestData.description.theme),callback:function ($$v) {_vm.$set(_vm.requestData.description, "theme", $$v)},expression:"requestData.description.theme"}})]},proxy:true},{key:"info",fn:function(){return [_c('a-alert',{staticClass:"d-none",class:{
                        'position--absolute': _vm.$vuetify.breakpoint.mdAndUp
                    },attrs:{"type":"info","data-type":"toggleable-info","data-alert":"alert-theme"}},[_c('p',{staticClass:"mb-2"},[_vm._v(" Explain the news or message you want to get out, and any other pertinent information we should mention. ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" What's the main service/product you want promoted and, if applicable, what's your target area and/or location? ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Include anything in particular you'd like to see in the Amp (specific service/product features, main benefits, awards won etc). ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" If there's anything crucial you'd like to be included in the Amp, mention that in your comment to make sure our writers create the content accordingly. ")])])]},proxy:true}],null,false,3751703818)}):_vm._e(),(!_vm.companyControl.show)?_c('stepper-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('a-text-input',{attrs:{"textarea":"","rules":"required","custom-messages":{
                        required: 'Provide details for your amp'
                    },"label":"What are your target keywords for this campaign?","placeholder":"I want to target...","rows":"5","auto-grow":""},on:{"focus":function($event){return _vm.showInfoAndFit('detail')}},model:{value:(_vm.requestData.description.details),callback:function ($$v) {_vm.$set(_vm.requestData.description, "details", $$v)},expression:"requestData.description.details"}})]},proxy:true},{key:"info",fn:function(){return [_c('a-alert',{staticClass:"d-none",class:{
                        'position--absolute': _vm.$vuetify.breakpoint.mdAndUp
                    },attrs:{"type":"info","data-type":"toggleable-info","data-alert":"alert-detail"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" What's the main service/product you want promoted and, if applicable, what's your target area and/or location? ")])])]},proxy:true}],null,false,2483670576)}):_vm._e(),(!_vm.companyControl.show)?_c('stepper-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('a-text-input',{attrs:{"rules":"linkCount","custom-messages":{
                        linkCount:
                            'Provide at least one valid link, including protocol (http / https), for your Amp'
                    },"label":"Provide a link (or links) that you would like to promote with this Amp","auto-grow":"","placeholder":"http(s)://website.com"},on:{"focus":function($event){return _vm.showInfoAndFit('url')}},model:{value:(_vm.requestData.description.links),callback:function ($$v) {_vm.$set(_vm.requestData.description, "links", $$v)},expression:"requestData.description.links"}})]},proxy:true},{key:"info",fn:function(){return [_c('a-alert',{staticClass:"d-none",class:{
                        'position--absolute': _vm.$vuetify.breakpoint.mdAndUp
                    },attrs:{"type":"info","data-type":"toggleable-info","data-alert":"alert-url"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" Provide links to your website, product, service or business. The more links you provide the more information we can gather. ")])]),(
                        _vm.containsLinks &&
                        !_vm.allLinksReachable &&
                        _vm.$vuetify.breakpoint.mdAndDown
                    )?_c('a-alert',{attrs:{"type":"warning","data-type":"warning"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" One or more of the links provided could not be reached. Please ensure that all links provided are accessible. ")])]):_vm._e()]},proxy:true}],null,false,4263392807)}):_vm._e(),(!_vm.companyControl.show)?_c('stepper-actions',{attrs:{"hide-back-button":""},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"info",fn:function(){return [(_vm.containsLinks && !_vm.allLinksReachable)?_c('a-alert',{attrs:{"type":"warning","data-type":"warning"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" One or more of the links provided could not be reached. Please ensure that all links provided are accessible. ")])]):_vm._e()]},proxy:true}:null],null,true)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }