<template>
    <v-container class="wizard__content--item-container px-0">
        <a-form ref="form" :submit="submit">
            <stepper-row v-if="!companyControl.show">
                <template #input>
                    <p class="text-body-2">
                        Our in-house authors will create you the ideal Amp. Just
                        answer the following questions and include your media
                        files. We'll jazz it up with the right language to grab
                        attention and gain traffic and visibility.
                    </p>
                </template>

                <template #info>
                    <v-skeleton-loader
                        v-if="companyControl.loading"
                        type="list-item-avatar-three-line"
                    />

                    <company-card-small
                        v-if="
                            !companyControl.loading &&
                            companyControl.selectedCompany &&
                            $vuetify.breakpoint.mdAndUp
                        "
                        :company="companyControl.selectedCompany"
                        @click="companyControl.show = true"
                    />
                </template>
            </stepper-row>

            <stepper-row v-if="!companyControl.show">
                <template #input>
                    <v-row>
                        <v-col
                            md="6"
                            :class="{
                                'col-8': $vuetify.breakpoint.xsOnly
                            }"
                        >
                            <a-select-input
                                v-model="requestData.company_id"
                                label="Company"
                                rules="required"
                                :items="companies"
                                item-text="name"
                                item-value="id"
                                :disabled="loading"
                                placeholder="Select a company"
                            />
                        </v-col>

                        <v-col
                            :class="{
                                'col-4': $vuetify.breakpoint.xsOnly,
                                'pt-6': $vuetify.breakpoint.mdAndUp
                            }"
                        >
                            <v-btn
                                v-if="companyControl.selectedCompany"
                                text
                                :disabled="loading"
                                @click.prevent="companyControl.show = true"
                            >
                                Edit
                                <v-icon right small color="primary">
                                    pencil
                                </v-icon>
                            </v-btn>

                            <v-btn
                                text
                                :disabled="loading"
                                @click.prevent="addNewCompany"
                            >
                                Add
                                <v-icon right small color="primary">
                                    plus-circle
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </stepper-row>

            <!-- company editor -->
            <stepper-row v-if="companyControl.show">
                <template #input>
                    <company-editor
                        ref="companyEditor"
                        :company-id="selectedCompanyId"
                        :is-creating="companyControl.isCreating"
                        class="px-0 pt-0"
                        @cancelled="onCompanyCancelled"
                        @saved="onCompanySaved"
                    >
                        <template #loading>
                            <v-skeleton-loader loading type="article" />
                        </template>
                    </company-editor>
                </template>
            </stepper-row>

            <stepper-row v-if="!companyControl.show">
                <template #input>
                    <a-text-input
                        v-model="requestData.description.theme"
                        textarea
                        rules="required"
                        :custom-messages="{
                            required: 'Provide a theme for your amp'
                        }"
                        label="Recommend a main theme for your Amp"
                        placeholder="I want it to focus on ... and ..."
                        rows="5"
                        auto-grow
                        @focus="showInfoAndFit('theme')"
                    />
                </template>

                <template #info>
                    <a-alert
                        type="info"
                        class="d-none"
                        data-type="toggleable-info"
                        data-alert="alert-theme"
                        :class="{
                            'position--absolute': $vuetify.breakpoint.mdAndUp
                        }"
                    >
                        <p class="mb-2">
                            Explain the news or message you want to get out, and
                            any other pertinent information we should mention.
                        </p>

                        <p class="mb-2">
                            What's the main service/product you want promoted
                            and, if applicable, what's your target area and/or
                            location?
                        </p>

                        <p class="mb-2">
                            Include anything in particular you'd like to see in
                            the Amp (specific service/product features, main
                            benefits, awards won etc).
                        </p>

                        <p class="mb-0">
                            If there's anything crucial you'd like to be
                            included in the Amp, mention that in your comment to
                            make sure our writers create the content
                            accordingly.
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-row v-if="!companyControl.show">
                <template #input>
                    <a-text-input
                        v-model="requestData.description.details"
                        textarea
                        rules="required"
                        :custom-messages="{
                            required: 'Provide details for your amp'
                        }"
                        label="What are your target keywords for this campaign?"
                        placeholder="I want to target..."
                        rows="5"
                        auto-grow
                        @focus="showInfoAndFit('detail')"
                    />
                </template>

                <template #info>
                    <a-alert
                        class="d-none"
                        type="info"
                        data-type="toggleable-info"
                        data-alert="alert-detail"
                        :class="{
                            'position--absolute': $vuetify.breakpoint.mdAndUp
                        }"
                    >
                        <p class="mb-0">
                            What's the main service/product you want promoted
                            and, if applicable, what's your target area and/or
                            location?
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-row v-if="!companyControl.show">
                <template #input>
                    <a-text-input
                        v-model="requestData.description.links"
                        rules="linkCount"
                        :custom-messages="{
                            linkCount:
                                'Provide at least one valid link, including protocol (http / https), for your Amp'
                        }"
                        label="Provide a link (or links) that you would like to promote with this Amp"
                        auto-grow
                        placeholder="http(s)://website.com"
                        @focus="showInfoAndFit('url')"
                    />
                </template>

                <template #info>
                    <a-alert
                        type="info"
                        class="d-none"
                        data-type="toggleable-info"
                        data-alert="alert-url"
                        :class="{
                            'position--absolute': $vuetify.breakpoint.mdAndUp
                        }"
                    >
                        <p class="mb-0">
                            Provide links to your website, product, service or
                            business. The more links you provide the more
                            information we can gather.
                        </p>
                    </a-alert>
                    <a-alert
                        v-if="
                            containsLinks &&
                            !allLinksReachable &&
                            $vuetify.breakpoint.mdAndDown
                        "
                        type="warning"
                        data-type="warning"
                    >
                        <p class="mb-0">
                            One or more of the links provided could not be
                            reached. Please ensure that all links provided are
                            accessible.
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-actions v-if="!companyControl.show" hide-back-button>
                <template v-if="$vuetify.breakpoint.mdAndUp" #info>
                    <a-alert
                        v-if="containsLinks && !allLinksReachable"
                        type="warning"
                        data-type="warning"
                    >
                        <p class="mb-0">
                            One or more of the links provided could not be
                            reached. Please ensure that all links provided are
                            accessible.
                        </p>
                    </a-alert>
                </template>
            </stepper-actions>
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import type { Company, CompanyControl } from '@/types/Company';
import type { ServerResponse } from '@/types/ServerResponse';

import { InfoFieldMixin, ValidateLinksReachability } from '@/mixins';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { CompanyEditor } from '@/components/CompanyEditor';
import { CompanyCardSmall } from '@/components/CompanyCardSmall';
import { StepperActions, StepperRow } from '@/components/Stepper';
import { AuthorRequestData } from '@/types/AuthorRequest';

const GetStartedProps = Vue.extend({
    props: {
        companies: {
            type: Array as PropType<Pick<Company, 'id' | 'name'>[]>,
            default() {
                return [];
            }
        }
    }
});

@Component({
    components: {
        AForm,
        StepperActions,
        AAlert,
        CompanyEditor,
        CompanyCardSmall,
        StepperRow,
        ASelectInput,
        ATextInput
    }
})
export default class GetStarted extends mixins(
    InfoFieldMixin,
    GetStartedProps,
    ValidateLinksReachability
) {
    $refs!: {
        form: InstanceType<typeof AForm>;
        companyEditor: InstanceType<typeof CompanyEditor>;
    };

    @InjectReactive({
        from: 'requestData',
        default() {
            return {};
        }
    })
    requestData!: AuthorRequestData;

    @Watch('requestData.company_id')
    onCompanyIdChange(companyId: number) {
        if (companyId) {
            this.companyControl.loading = true;

            this.$http
                .get<ServerResponse<{ company: Company }>>(
                    `/companies/view/${companyId}`
                )
                .then(({ data }) => {
                    this.$emit('companyChanged', data.data.company);

                    this.companyControl.selectedCompany = data.data.company;
                })
                .finally(() => {
                    this.companyControl.loading = false;
                });
        }
    }

    @Watch('requestData.description.links')
    onLinkChange(links: string) {
        this.validateLinksReachability(links, false);
    }

    companyControl: CompanyControl = {
        show: false,
        loading: false,
        isCreating: false,
        selectedCompany: null
    };

    get loading() {
        return this.$store.state.loading.show;
    }

    get selectedCompanyId() {
        return !this.companyControl.isCreating &&
            this.companyControl.selectedCompany
            ? this.companyControl.selectedCompany.id
            : 0;
    }

    get hasChanged() {
        return this.$refs.form.hasChanged;
    }

    getCompanyData(id: Company['id']) {
        this.companyControl.loading = true;

        return this.$http
            .get<ServerResponse<{ company: Company }>>(`/companies/view/${id}`)
            .then(({ data }) => data)
            .finally(() => {
                this.companyControl.loading = false;
            });
    }

    addNewCompany() {
        this.companyControl.isCreating = true;

        this.companyControl.show = true;
    }

    onCompanySaved({ data }: ServerResponse<{ company: Company }>) {
        this.getCompanyData(data.company.id).then(({ data }) => {
            this.companyControl.selectedCompany = data.company;

            this.companyControl.isCreating = false;

            this.companyControl.show = false;

            this.$emit('companySaved', data.company);
        });
    }

    onCompanyCancelled() {
        this.companyControl.isCreating = false;

        this.companyControl.show = false;
    }

    submit() {
        this.$emit('next');
    }

    validateCompanyEditor() {
        this.$refs.companyEditor.getData().then(() => {
            this.$refs.companyEditor.$refs.form.validate();
        });
    }
}
</script>
